import { Injectable } from '@angular/core';
import { ApiCoreService } from 'src/app/services/api/core.service';
import { AlertController } from '@ionic/angular';
import { ECantidadBotones, IBotonesAlerta } from '../models/alertas';

@Injectable({
  providedIn: 'root',
})
export class AlertasService {
  ECantidadBotones = ECantidadBotones;
  modoDebug: boolean;

  constructor(
    private core: ApiCoreService,
    private alertController: AlertController
  ) {
    this.modoDebug = this.core.modoDebug();
  }

  /**
   * @param mensaje - Mensaje de la laerta
   * @param titulo - Título de la alerta
   * @param cantidad - cantidad de botones
   */
  async presentarAlerta(
    mensaje: string,
    titulo: string,
    cantidad: ECantidadBotones
  ) {
    try {
      const botones: IBotonesAlerta[] = this.botonesAlerta(cantidad);
      const alert = await this.alertController.create({
        header: titulo,
        message: mensaje,
        cssClass: 'alertGeneral',
        buttons: botones,
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      return role;
    } catch (error) {
      throw error;
    }
  }

  botonesAlerta(cantidad: ECantidadBotones) {
    let botones: IBotonesAlerta[];
    switch (cantidad) {
      case this.ECantidadBotones.UNO:
        botones = [
          {
            text: 'OK',
            cssClass: 'alert-button-confirm',
            role: 'confirm',
          },
        ];

        break;
      case this.ECantidadBotones.DOS:
        botones = [
          {
            text: 'Cancelar',
            cssClass: 'alert-button-cancel',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            cssClass: 'alert-button-confirm',
            role: 'confirm',
          },
        ];
        break;
      default:
        break;
    }

    return botones;
  }
}
