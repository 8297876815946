import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public loggedIn: boolean = false;
  private user: any;
  private title: string = '';

  constructor() {
    this.user = null;
  }

  clearStarted() {
    const started = environment.app + '-started';
    localStorage.removeItem(started);
  }

  getAccessToken() {
    //const token = localStorage.getItem('accessToken');
    const tokenName = environment.app + '-accessToken';
    const token = localStorage.getItem(tokenName);
    return token || null;
  }

  getTitle() {
    return this.title;
  }

  getToken() {
    const tokenName = environment.app + '-jwtToken';
    const token = localStorage.getItem(tokenName);
    return token || null;
  }

  getUser() {
    return this.user;
  }

  getUsername() {
    return this.getUser().getUsername();
  }

  isAuthenticated() {
    const tokenName = environment.app + '-accessToken';
    const t = localStorage.getItem(tokenName);
    if (t) return true;
    return false;
  }

  isStarted() {
    const started = environment.app + '-started';
    const s = localStorage.getItem(started);
    return s == 'true';
  }

  async logout() {
    const accessName = environment.app + '-accessToken';
    const jwtName = environment.app + '-jwtToken';
    localStorage.removeItem(accessName);
    localStorage.removeItem(jwtName);
  }

  setTitle(t) {
    this.title = t;
  }

  async setToken(t: any) {
    return new Promise((resolve, reject) => {
      const accessName = environment.app + '-accessToken';
      const jwtName = environment.app + '-jwtToken';
      localStorage.setItem(accessName, t.access_token);
      localStorage.setItem(jwtName, t.id_token);
      return resolve('OK');
    });
  }

  start() {
    const started = environment.app + '-started';
    localStorage.setItem(started, 'true');
  }
}
