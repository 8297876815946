/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/naming-convention */
import { ApiCommonService } from './api-common.service';

import { Ajuste } from 'src/app/models/ajuste';
import { Aprendizaje } from 'src/app/models/aprendizaje';
import { Barrera } from 'src/app/models/barrera';
import { Dimension } from 'src/app/models/dimension';
import { Discapacidad } from 'src/app/models/discapacidad';
import { Disgusto } from 'src/app/models/disgusto';
import { Grado } from 'src/app/models/grado';
import { Gusto } from 'src/app/models/gusto';
import { Herramienta } from 'src/app/models/herramienta';
import { Lenguaje } from 'src/app/models/lenguaje';
import { Personalidad } from 'src/app/models/personalidad';
import { Preocupacion } from 'src/app/models/preocupacion';
import { Pregunta } from 'src/app/models/pregunta';
import { Experto } from 'src/app/models/experto';
import { Tema } from 'src/app/models/tema';
import { Keyword } from 'src/app/models/keyword';
import { Video } from 'src/app/models/video';
import { Herramientaind } from 'src/app/models/herramientaind';
import { Tip } from 'src/app/models/tips';
import { PreguntaIdenCausas } from 'src/app/models/preguntaIdenCausas';
import { Objetivo } from 'src/app/models/objetivo';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCoreService {
  constructor(
    private common: ApiCommonService,
    private http: HttpClient,
    private user: UserService
  ) {}

  public modoDebug() {
    return this.common.modoDebug();
  }

  /**************************** DELETE *******************************/

  deleteAjuste(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/ajuste/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteAprendizaje(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteBarrera(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/barrera/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteDimension(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/dimension/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteDiscapacidad(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteDisgusto(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/disgusto/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteExperto(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/experto/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteGrado(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/grado/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteGusto(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/gusto/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteHerramienta(idAjuste: string, id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta/',
      idAjuste + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteHerramientaind(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteAjusteObjetivo(idAjuste: string, idObjetivo: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/objetivo/',
      idAjuste + '/' + idObjetivo
    );
    if (this.common.modoDebug()) console.log(url);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteKeyword(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/keyword/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteLenguaje(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deletePersonalidad(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/personalidad/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deletePregunta(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/pregunta/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deletePreguntaIdenCausas(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deletePreocupacion(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  deleteTema(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tema/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteTip(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tip/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteVideo(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/video/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  deleteObjetivo(id: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/objetivo/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  /******************************QUERIES******************************************************************/

  getAjuste(id: string): Observable<Ajuste> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/ajuste/',
      id
    );
    return this.http
      .get<Ajuste>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getAprendizaje(id: string): Observable<Aprendizaje> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje/',
      id
    );
    return this.http
      .get<Aprendizaje>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getBarrera(id: string): Observable<Barrera> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/barrera/',
      id
    );
    return this.http
      .get<Barrera>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getConsultarExpertoVideo(uid: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'experto/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getTip(id: string): Observable<Tip> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tip/',
      id
    );
    return this.http
      .get<Tip>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }
  getObjetivo(id: string): Observable<Objetivo> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/objetivo/',
      id
    );
    return this.http
      .get<Objetivo>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }
  getDimension(id: string): Observable<Dimension> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/dimension/',
      id
    );
    return this.http
      .get<Dimension>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getDiscapacidad(id: string): Observable<Discapacidad> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad/',
      id
    );
    return this.http
      .get<Discapacidad>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getDisgusto(id: string): Observable<Disgusto> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/disgusto/',
      id
    );
    return this.http
      .get<Disgusto>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getExperto(id: string): Observable<Experto> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/experto/',
      id
    );
    return this.http
      .get<Experto>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }
  getGrado(id: string): Observable<Grado> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/grado/',
      id
    );
    return this.http
      .get<Grado>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getGusto(id: string): Observable<Gusto> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/gusto/',
      id
    );
    return this.http
      .get<Gusto>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getHerramienta(idAjuste: string, id: string): Observable<Herramienta> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta/',
      idAjuste + '/' + id
    );
    return this.http
      .get<Herramienta>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }
  getHerramientaind(id: string): Observable<Herramientaind> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind/',
      id
    );
    return this.http
      .get<Herramientaind>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getKeyword(id: string): Observable<Keyword> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/keyword/',
      id
    );
    return this.http
      .get<Keyword>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }
  getLenguaje(id: string): Observable<Lenguaje> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje/',
      id
    );
    return this.http
      .get<Lenguaje>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getListarAjustes(useCache = true) {
    const url: string = this.common.makeDataURL('datos_basicos', 'ajustes', '');
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }
  getListarPreguntas() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/pregunta',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarPreguntaIdenCausas() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/preguntaidencausas',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarExpertos() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/experto',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarTemas() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/tema',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarKeywords() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/keyword',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarVideos() {
    // const pdata  = JSON.stringify(null);
    // console.log(pdata)
    // const url: string = this.common.makeDataURL('datos_basicos', 'entidades/video/'+pdata, '');
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/video',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarVideosAllInfo() {
    const url: string = this.common.makeDataURL('datos_basicos', 'videos', '');
    return this.common.getCachedData(url);
  }
  getListarVideosAllInfoDocente() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'videosDocente',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarVideosAllInfoFamilia() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'videosFamilia',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarHerramientaIndAllInfo() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'herramientaind',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarTip() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/tip',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarObjetivo() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/objetivo',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarAprendizajes(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/aprendizaje',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarAprendizajesEtapa(etapa, useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades-etapa/aprendizaje/',
      etapa
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarBarreras(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/barrera',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }
  getGrados() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/grado',
      ''
    );
    return this.common.getCachedData(url);
  }
  getListarCiudades(pais: string, provincia: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/ciudad-' + pais + '-' + provincia,
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarDimensiones(locale) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/dimension/',
      locale
    );
    return this.common.getCachedData(url);
  }

  getobjetivosDimesion(etapa, dimenciones) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'llamar-objetivos/',
      etapa
    );
    const pdata = JSON.stringify(dimenciones);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarDimensionesNueva(etapa: string, useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades-etapa/dimension/',
      etapa
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarDiscapacidades(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/discapacidad',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarDisgustos(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/disgusto',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarGrados(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/grado',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarGustos(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/gusto',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarHerramientas(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }
  getListarHerramientaind() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/herramientaind',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarLenguajes() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/lenguaje',
      ''
    );
    return this.common.getCachedData(url);
  }

  getListarMaterias(locale: any) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/materia/',
      locale
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarMomentos() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/momento',
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarPaises() {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/pais',
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarPersonalidades(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/personalidad',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarPreocupaciones(useCache = true) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/preocupacion',
      ''
    );
    if (useCache) return this.common.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.common.extractData)
        .catch(this.common.handleError);
    }
  }

  getListarProvincias(pais: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidades/provincia-' + pais,
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getPersonalidad(id: string): Observable<Personalidad> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/personalidad/',
      id
    );
    return this.http
      .get<Personalidad>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getPregunta(id: string): Observable<Pregunta> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/pregunta/',
      id
    );
    return this.http
      .get<Pregunta>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getPreguntaIdenCausas(id: string): Observable<PreguntaIdenCausas> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas/',
      id
    );
    return this.http
      .get<PreguntaIdenCausas>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getPreocupacion(id: string): Observable<Preocupacion> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion/',
      id
    );
    return this.http
      .get<Preocupacion>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getProfesoraSkalo(id) {
    const url: string = this.common.makeDataURL(
      'experto',
      'profesoraskalo/profesora-skalo/',
      id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getTema(id: string): Observable<Tema> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tema/',
      id
    );
    return this.http
      .get<Tema>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  getVerDetalleAjuste(uid: string) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getVideo(id: string): Observable<Video> {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/video/',
      id
    );
    return this.http
      .get<Video>(url)
      .pipe(retry(2), catchError(this.common.handleError));
  }

  /**
   * Grabar ajuste
   * @param ajuste
   */
  grabarAjuste(ajuste: Ajuste) {
    const url: string = this.common.makeDataURL('datos_basicos', 'ajuste', '');
    const pdata = JSON.stringify({
      ajuste: {
        codigo: ajuste.attributes.codigo,
        descripcion: ajuste.attributes.descripcion,
        imagen: ajuste.attributes.imagen,
        momento: ajuste.attributes.momento,
        puntaje: ajuste.attributes.puntaje,
        video: ajuste.attributes.video,
        familia: ajuste.attributes.familia,
        docente: ajuste.attributes.docente,
        script: ajuste.attributes.script,
        etapas: {
          pre: ajuste.attributes.etapaPre,
          pri: ajuste.attributes.etapaPri,
          bac: ajuste.attributes.etapaBac,
        },
        videos: {
          pre: ajuste.attributes.videoPre,
          pri: ajuste.attributes.videoPri,
          bac: ajuste.attributes.videoBac,
        },
        scripts: {
          pre: ajuste.attributes.scriptPre,
          pri: ajuste.attributes.scriptPri,
          bac: ajuste.attributes.scriptBac,
        },
        grados: ajuste.attributes.grados,
        locales: ajuste.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar ajuste
   * @param ajuste
   */
  grabarAjusteidioma(ajuste: Ajuste) {
    const url: string = this.common.makeDataURL('datos_basicos', 'ajuste', '');
    const pdata = JSON.stringify({
      ajuste: {
        codigo: ajuste.attributes.codigo,
        descripcion: ajuste.attributes.descripcion,
        imagen: ajuste.attributes.imagen,
        momento: ajuste.attributes.momento,
        puntaje: ajuste.attributes.puntaje,
        video: ajuste.attributes.video,
        familia: ajuste.attributes.familia,
        docente: ajuste.attributes.docente,
        script: ajuste.attributes.script,
        etapas: ajuste.attributes.etapas,
        videos: ajuste.attributes.videos,
        scripts: ajuste.attributes.scripts,
        grados: ajuste.attributes.grados,
        locales: ajuste.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar pregunta
   * @param pregunta
   */
  grabarPregunta(pregunta: Pregunta) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/pregunta',
      ''
    );
    // console.log(pregunta)
    const pdata = JSON.stringify({
      entidad: {
        codigo: pregunta.attributes.codigoFinal,
        descripcion: pregunta.attributes.descripcion,
        codigoDimension: pregunta.attributes.codigoDimension,
        puntaje: pregunta.attributes.puntaje,
        respuesta: pregunta.attributes.respuesta,
        etapas: {
          pre: pregunta.attributes.etapaPre,
          pri: pregunta.attributes.etapaPri,
          bac: pregunta.attributes.etapaBac,
        },
        orden: pregunta.attributes.orden,
        grados: pregunta.attributes.grados,
        locales: [
          { locale: 'en', descripcion: pregunta.attributes.descripcionEn },
        ],
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarProfesora(uidusuario: any, data: any) {
    const url: string = this.common.makeDataURL(
      'experto',
      'profesoraskalo',
      ''
    );

    const pdata = JSON.stringify({
      id: uidusuario,
      activo: data.activo,
      fecha: data.fecha,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar experto
   * @param experto
   */
  grabarExperto(experto: Experto) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/experto',
      ''
    );
    // console.log(experto)
    const pdata = JSON.stringify({
      entidad: {
        codigo: experto.attributes.codigo,
        descripcion: experto.attributes.descripcion,
        nombre: experto.attributes.nombre,
        // docente:experto.data.docente,
        // familia:experto.data.familia,
        imagen: experto.attributes.imagen,
        titulo: experto.attributes.titulo,
        videoPresentacion: experto.attributes.videoPre,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar tema
   * @param tema
   */
  grabarTema(tema: Tema) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tema',
      ''
    );
    // console.log(tema)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tema.attributes.codigo,
        descripcion: tema.attributes.descripcion,
        nombre: tema.attributes.nombre,
        docente: tema.attributes.docente,
        familia: tema.attributes.familia,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar keyword
   * @param keyword
   */
  grabarKeyword(keyword: Keyword) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/keyword',
      ''
    );
    // console.log(keyword)
    const pdata = JSON.stringify({
      entidad: {
        codigo: keyword.attributes.codigo,
        descripcion: keyword.attributes.descripcion,
        nombre: keyword.attributes.nombre,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar video
   * @param video
   */
  grabarVideo(video: Video) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/video',
      ''
    );
    // console.log(video)
    const pdata = JSON.stringify({
      entidad: {
        codigo: video.attributes.codigo,
        descripcion: video.attributes.descripcion,
        nombre: video.attributes.nombre,
        experto: video.attributes.experto,
        temas: video.attributes.temas,
        keywords: video.attributes.keywords,
        video: video.attributes.video,
        locales: video.attributes.locales,
        familia: video.attributes.familia,
        docente: video.attributes.docente,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar video
   * @param video
   */
  grabarVideoidioma(video: Video) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/video',
      ''
    );
    // console.log(video)
    const pdata = JSON.stringify({
      entidad: {
        codigo: video.attributes.codigo,
        descripcion: video.attributes.descripcion,
        nombre: video.attributes.nombre,
        experto: video.attributes.experto,
        temas: video.attributes.temas,
        keywords: video.attributes.keywords,
        video: video.attributes.video,
        locales: video.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar aprendizaje
   * @param aprendizaje
   */
  grabarAprendizaje(aprendizaje: Aprendizaje) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: aprendizaje.attributes.codigo,
        descripcion: aprendizaje.attributes.descripcion,
        nombre: aprendizaje.attributes.nombre,
        orden: aprendizaje.attributes.orden,
        etapas: {
          pre: aprendizaje.attributes.etapaPre,
          pri: aprendizaje.attributes.etapaPri,
          bac: aprendizaje.attributes.etapaBac,
        },
        locales: [
          {
            locale: 'en',
            nombre: aprendizaje.attributes.nombreEn,
            descripcion: aprendizaje.attributes.descripcionEn,
          },
        ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar barrera de aprendizaje
   * @param barrera
   */
  grabarBarrera(barrera: Barrera) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/barrera',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: barrera.attributes.codigo,
        nombre: barrera.attributes.nombre,
        locales: [{ locale: 'en', nombre: barrera.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  /**  * Grabar dimension * @param dimension */
  grabarDimension(dimension: Dimension) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/dimension',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: dimension.attributes.codigo,
        descripcion: dimension.attributes.descripcion,
        nombre: dimension.attributes.nombre,
        orden: dimension.attributes.orden,
        etapas: {
          pre: dimension.attributes.etapaPre,
          pri: dimension.attributes.etapaPri,
          bac: dimension.attributes.etapaBac,
        },
        locales: [
          {
            locale: 'en',
            nombre: dimension.attributes.nombreEn,
            descripcion: dimension.attributes.descripcionEn,
          },
        ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar discapacidad
   * @param discapacidad
   */
  grabarDiscapacidad_post(discapacidad: Discapacidad) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        nombre: discapacidad.attributes.nombre,
        codigo: discapacidad.attributes.codigo,
        locales: [{ locale: 'en', nombre: discapacidad.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar disgusto
   * @param disgusto
   */
  grabarDisgusto(disgusto: Disgusto) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/disgusto',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: disgusto.attributes.codigo,
        descripcion: disgusto.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar Grado Escolar
   * @param grado
   */
  grabarGrado_post(grado: Grado) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/grado',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        nombre: grado.attributes.nombre,
        codigo: grado.attributes.codigo,
        locales: [{ locale: 'en', nombre: grado.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar gusto
   * @param gusto
   */
  grabarGusto(gusto: Gusto) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/gusto',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: gusto.attributes.codigo,
        descripcion: gusto.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar Herramienta
   * @param herramienta
   * @param ajuste
   */
  grabarHerramienta(herramienta: Herramienta, ajuste: Ajuste) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      herramienta: {
        id: herramienta.attributes.id,
        orden: herramienta.attributes.orden,
        nombre: herramienta.attributes.nombre,
        tipo: herramienta.attributes.tipo,
        contenido: herramienta.attributes.contenido,
        script: herramienta.attributes.script,
        videos: {
          pre: herramienta.attributes.videoPre,
          pri: herramienta.attributes.videoPri,
          bac: herramienta.attributes.videoBac,
        },
        scripts: {
          pre: herramienta.attributes.scriptPre,
          pri: herramienta.attributes.scriptPri,
          bac: herramienta.attributes.scriptBac,
        },
        locales: herramienta.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar herramienta
   * @param herramienta
   * @param ajuste
   */
  grabarHerramientadiomas(herramienta: Herramienta, ajuste: Ajuste) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      herramienta: {
        id: herramienta.attributes.id,
        orden: herramienta.attributes.orden,
        nombre: herramienta.attributes.nombre,
        tipo: herramienta.attributes.tipo,
        contenido: herramienta.attributes.contenido,
        script: herramienta.attributes.script,
        videos: herramienta.attributes.videos,
        scripts: herramienta.attributes.scripts,
        locales: herramienta.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar herramientaind
   * @param herramientaind
   */
  grabarHerramientaind(herramientaind: Herramientaind) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: herramientaind.attributes.codigo,
        contenido: herramientaind.attributes.contenido,
        nombre: herramientaind.attributes.nombre,
        tipo: herramientaind.attributes.tipo,
        videos: {
          pre: herramientaind.attributes.videoPre,
          pri: herramientaind.attributes.videoPri,
          bac: herramientaind.attributes.videoBac,
        },
        scripts: {
          pre: herramientaind.attributes.scriptPre,
          pri: herramientaind.attributes.scriptPri,
          bac: herramientaind.attributes.scriptBac,
        },
        locales: herramientaind.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar tip
   * @param tip
   */
  grabarTip(tip: Tip) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tip',
      ''
    );
    // console.log(tip)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tip.attributes.codigo,
        nombre: tip.attributes.nombre,
        script: tip.attributes.script,
        familia: tip.attributes.familia,
        docente: tip.attributes.docente,
        imagen: tip.attributes.imagen,
        locales: tip.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
  /**
   * Grabar tip
   * @param tip
   */
  grabarTipIdioma(tip: Tip) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/tip',
      ''
    );
    // console.log(tip)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tip.attributes.codigo,
        nombre: tip.attributes.nombre,
        script: tip.attributes.script,
        familia: tip.attributes.familia,
        docente: tip.attributes.docente,
        imagen: tip.attributes.imagen,
        locales: tip.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar tip
   * @param tip
   */
  grabarObjetivo(objetivo: Objetivo) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/objetivo',
      ''
    );
    const pdata = JSON.stringify({
      entidad: {
        codigo: objetivo.attributes.codigo,
        nombre: objetivo.attributes.nombre,
        imagen: objetivo.attributes.imagen,
        locales: objetivo.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarObjetivoIdioma(objetivo: Objetivo) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/objetivo',
      ''
    );
    const pdata = JSON.stringify({
      entidad: {
        codigo: objetivo.attributes.codigo,
        nombre: objetivo.attributes.nombre,
        imagen: objetivo.attributes.imagen,
        locales: objetivo.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  grabarAjusteObjetivo(objetivo, ajuste) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/objetivo',
      ''
    );

    const pdata = JSON.stringify({
      ajusteId: ajuste,
      objetivoId: objetivo,
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar herramientaind
   * @param herramientaind
   */
  grabarHerramientaindidiomas(herramientaind: Herramientaind) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: herramientaind.attributes.codigo,
        contenido: herramientaind.attributes.contenido,
        nombre: herramientaind.attributes.nombre,
        tipo: herramientaind.attributes.tipo,
        videos: herramientaind.attributes.videos,
        scripts: herramientaind.attributes.scripts,
        locales: herramientaind.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar lenguaje
   * @param lenguaje
   */
  grabarLenguaje(lenguaje: Lenguaje) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: lenguaje.attributes.codigo,
        descripcion: lenguaje.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar personalidad
   * @param personalidad
   */
  grabarPersonalidad(personalidad: Personalidad) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/personalidad',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: personalidad.attributes.codigo,
        nombre: personalidad.attributes.nombre,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar preguntaidencausas
   * @param preguntaidencausas
   */
  grabarPreguntaIdenCausas(pregunta: PreguntaIdenCausas) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: pregunta.attributes.codigo,
        nombre: pregunta.attributes.nombre,
        respuestas: pregunta.attributes.respuestas,
      },
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar preocupacion
   * @param preocupacion
   */
  grabarPreocupacion(preocupacion: Preocupacion) {
    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: preocupacion.attributes.codigo,
        descripcion: preocupacion.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar todos los puntajes de una categoría para un ajuste
   * @param codigoAjuste
   * @param tipo
   * @param puntajes
   */
  grabarPuntajesAjuste(
    codigoAjuste: string,
    tipo: string,
    etapa: string,
    puntajes: any
  ) {
    var arreglo: any = [];
    puntajes.forEach(p => {
      const e = {
        codigo: p.codigo,
        puntos: p.puntos,
      };
      arreglo.push(e);
    });

    const pdata = {
      ajuste: {
        codigo: codigoAjuste,
        tipo: tipo,
        etapa: etapa,
      },
      puntajes: arreglo,
    };

    const url: string = this.common.makeDataURL(
      'datos_basicos',
      'ajuste/puntajes',
      ''
    );
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
}
