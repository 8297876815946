import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCoreService } from './api/core.service';

declare const AWS: any;

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor(private apiCore: ApiCoreService) {}

  /**
   * Descarga un archivo de S3
   *
   * @param bucket - Bucket público donde está el archivo a descargar
   * @param filePath - path y nombre con extensión del archivo a descargar
   * @param contentType - MIME type del archivo a descargar
   * @param fileName - nombre que llevará el archivo descargado
   */
  public async generateDocument(
    bucket: string,
    filePath: string,
    contentType: string,
    fileName: string
  ) {
    try {
      const blob = (await this.getFileAsBlob(
        bucket,
        filePath,
        contentType
      )) as Blob;

      await this.download(blob, fileName);
    } catch (error) {
      throw error;
    }
  }

  public async uploadFile(
    key: string,
    data: string | Buffer | File,
    contentType: string
  ) {
    const bucket = new AWS.S3({
      accessKeyId: environment.S3accessKey,
      secretAccessKey: environment.S3accessSecret,
      region: environment.S3region,
    });

    const params = {
      Bucket: environment.S3bucket,
      Key: key,
      Body: data,
      ContentType: contentType,
    };

    try {
      await bucket.upload(params).promise();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Trae el objeto de S3 y lo convierte en Blob
   *
   * @param bucket - Bucket público donde está el archivo a descargar
   * @param filePath - path y nombre con extensión del archivo a descargar
   * @param contentType - MIME type del archivo a descargar
   */
  private async getFileAsBlob(
    bucket: string,
    filePath: string,
    contentType: string
  ): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      try {
        const s3 = new AWS.S3({
          accessKeyId: environment.S3accessKey,
          secretAccessKey: environment.S3accessSecret,
          region: environment.S3region,
        });
        const params = {
          Bucket: bucket,
          Key: filePath,
          ResponseContentType: contentType,
        };
        const data = await s3.getObject(params).promise();
        resolve(new Blob([data.Body], { type: contentType }));
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   *
   * @param blob - Objeto Blob que será descargado
   * @param fileName - Nombre que llevará el archivo, debe incluir la extension
   */
  private async download(blob: Blob, fileName: string) {
    return new Promise((resolve, reject) => {
      try {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        link.closest;
        resolve('OK');
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Sirve para cuando se necesita convertir un Buffer a Base64String
   *
   * @param buffer
   */
  private async bufferToBase64(buffer: Buffer) {
    const binstr = Array.prototype.map
      .call(buffer, ch => String.fromCharCode(ch))
      .join('');
    return btoa(binstr);
  }
}
