import { ApiCommonService } from './services/api/api-common.service';
import { AppComponent } from './app.component';
import { ApiEnrolamientoService } from './services/api/enrolamiento.service';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Cognito } from './services/aws-cognito.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { TokenResolverService } from './services/token-resolver.service';
import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import {
  ApplicationInitializerFactory,
  HttpLoaderFactory,
} from './translation.config';
import { DragulaModule } from 'ng2-dragula';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { S3Service } from './services/aws-s3.service';
import { AlertasService } from './services/alertas.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
declare var AWS;
AWS.config.customUserAgent = AWS.config.customUserAgent + ' Ionic';
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    //IonicStorageModule.forRoot(),
    DragulaModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxHotjarModule.forRoot(environment.hotjar),
    NgxHotjarRouterModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    ApiService,
    ApiCommonService,
    ApiEnrolamientoService,
    Cognito,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TokenResolverService,
    UserService,
    S3Service,
    AlertasService,
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
