import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { UserService } from './services/user.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const defaultLang = 'es';

    translate.addLangs(['en', 'es']);
    translate.setDefaultLang(defaultLang);
    try {
      await translate.use(defaultLang).toPromise();
    } catch (err) {
      if (environment.debug) console.error(err);
    }
    if (environment.debug)
      console.log(`Successfully initialized ${defaultLang} language.`);
  };
}
