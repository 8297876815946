import { CallbackComponent } from './components/callback/callback.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TokenResolverService } from './services/token-resolver.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/menu/menu.module').then(m => m.MenuPageModule),
  },
  {
    path: 'callback',
    component: CallbackComponent,
    resolve: {
      access: TokenResolverService,
    },
  },
  {
    path: 'asignar-logro',
    loadChildren: () =>
      import('./pages/asignar-logro/asignar-logro.module').then(
        m => m.AsignarLogroPageModule
      ),
  },
  {
    path: 'camera-modal',
    loadChildren: () =>
      import('./pages/camera-modal/camera-modal.module').then(
        m => m.CameraModalPageModule
      ),
  },
  {
    path: 'cambio-anio',
    loadChildren: () =>
      import('./pages/cambio-anio/cambio-anio.module').then(
        m => m.CambioAnioPageModule
      ),
  },
  {
    path: 'detalle-ajuste',
    loadChildren: () =>
      import('./pages/detalle-ajuste/detalle-ajuste.module').then(
        m => m.DetalleAjustePageModule
      ),
  },
  {
    path: 'encuesta-video',
    loadChildren: () =>
      import('./pages/encuesta-video/encuesta-video.module').then(
        m => m.EncuestaVideoPageModule
      ),
  },
  {
    path: 'identificacion-causas',
    loadChildren: () =>
      import('./pages/identificacion-causas/identificacion-causas.module').then(
        m => m.IdentificacionCausasPageModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./pages/menu/menu.module').then(m => m.MenuPageModule),
  },
  {
    path: 'pre-ajustes',
    loadChildren: () =>
      import('./pages/pre-ajustes/pre-ajustes.module').then(
        m => m.PreAjustesPageModule
      ),
  },
  {
    path: 'preguntas-adicionales',
    loadChildren: () =>
      import('./pages/preguntas-adicionales/preguntas-adicionales.module').then(
        m => m.PreguntasAdicionalesPageModule
      ),
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./pages/expertos/quiz/quiz.module').then(m => m.QuizPageModule),
  },
  {
    path: 'resumen-informe-anio',
    loadChildren: () =>
      import('./pages/resumen-informe-anio/resumen-informe-anio.module').then(
        m => m.ResumenInformeAnioPageModule
      ),
  },
  {
    path: 'tips',
    loadChildren: () =>
      import('./pages/tips/tips.module').then(m => m.TipsPageModule),
  },
  {
    path: 'video-player',
    loadChildren: () =>
      import('./pages/video-player/video-player.module').then(
        m => m.VideoPlayerPageModule
      ),
  },
  {
    path: 'video-player-experto',
    loadChildren: () =>
      import('./pages/video-player-experto/video-player-experto.module').then(
        m => m.VideoPlayerExpertoPageModule
      ),
  },
  {
    path: 'video-capture-modal',
    loadChildren: () =>
      import('./pages/video-capture-modal/video-capture-modal.module').then(
        m => m.VideoCaptureModalPageModule
      ),
  },
  {
    path: 'valoracion',
    loadChildren: () =>
      import('./pages/valoracion/valoracion.module').then(
        m => m.ValoracionPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
