export type IBotonesAlerta = {
  text: string;
  cssClass: string;
  role: string;
};

export enum ECantidadBotones {
  UNO = 1,
  DOS = 2,
}
