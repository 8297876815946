import { Component, OnInit } from '@angular/core';
import { Config, MenuController, Platform } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user.service';
import { ApiEnrolamientoService } from 'src/app/services/api/enrolamiento.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isauthenticated = false;
  public isopened = false;
  public selectedIndex = 0;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  subscription: Subscription;

  constructor(
    public config: Config,
    private idle: Idle,
    private keepalive: Keepalive,
    private menuCtrl: MenuController,
    private platform: Platform,
    private router: Router,
    private translate: TranslateService,
    private user: UserService,
    private api: ApiEnrolamientoService
  ) {
    this.subscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    this.initializeApp();
    this.idle.setIdle(1800);
    this.idle.setTimeout(5);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });
    this.idle.onIdleStart.subscribe(
      () => (this.idleState = "You've gone idle!")
    );
    this.idle.onTimeoutWarning.subscribe(
      countdown =>
        (this.idleState = 'You will time out in ' + countdown + ' seconds!')
    );
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  async closemenu() {
    this.isopened = false;
    await this.menuCtrl.close();
  }

  async initializeApp() {
    try {
      await this.platform.ready();
      const data = await this.api.getUsuarioActual();
      if (data['data'][0].attributes.idioma != undefined) {
        this.translate.setDefaultLang(data['data'][0].attributes.idioma);
        await this.translate.use(data['data'][0].attributes.idioma).toPromise();
      } else {
        this.translate.currentLang = 'es';
        this.translate.setDefaultLang('es');
        await this.translate.use('es').toPromise();
      }
      await StatusBar.setStyle({ style: Style.Light });
      await SplashScreen.hide();
    } catch (err) {
      if (this.api.modoDebug()) {
        console.error(err);
      }
    }
  }

  async logout() {
    await this.user.logout();
    await this.menuCtrl.close();
    this.router.navigate(['/']);
    window.location.reload();
  }

  ngOnInit() {
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
